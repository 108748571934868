<template>
  <card
    :content="content"
    :schema="getSchemaLayingFarm"
    :form-data="getDataLayingFarm"
    :editable="getEditableLayingFarm"
    @update-form="updateLayingFarm"
    @submitAction="addLayingFarm"
  />
</template>

<script>
/* eslint-disable no-console */
import Card from "../components/Card";
import confLayingFarm from "../conf/confLayingFarm";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LayingFarm",
  components: { Card },
  title() {
    const locationName =
      this.$store.getters["company/get_locationName"] ??
      localStorage.getItem("locationName");
    return this.$t(this.$route.meta.title) + " – " + locationName;
  },

  data() {
    return {
      content: confLayingFarm.content,
      hidden: true
    };
  },
  created() {
    this.$store.dispatch("layingFarm/loadLayingFarm");
  },
  computed: {
    ...mapGetters("layingFarm", [
      "getDataLayingFarm",
      "getSchemaLayingFarm",
      "getEditableLayingFarm"
    ])
  },

  methods: {
    ...mapActions("layingFarm", [
      "updateLayingFarm",
      "addLayingFarm",
      "loadLayingFarm"
    ])
    /*checkSizes() {
      if (document.getElementById("content")) {
        return document.getElementById("content").clientWidth;
      } else if (document.getElementById("submitForm")) {
        return document.getElementById("submitForm").clientWidth;
      } else return window.screen.availWidth;
    }*/
  }

  /*mounted() {
    let hwidth = this.checkSizes();
    if (hwidth < 820)
      this.$store.state.layingFarm.schema.lfStables.dense = true;
  }*/
};
</script>

<style scoped></style>
